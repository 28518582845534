export var LabelsData = [
    {
        name: '路南街道综合文化站',
        position: [121.397144, 28.558803],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 16,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路南街道综合文化站',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥街道综合文化站',
        position: [121.374146, 28.568495],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 10,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥街道综合文化站',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },

    {
        name: '路北街道综合文化站',
        position: [121.362217, 28.576],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 8,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路北街道综合文化站',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '螺洋街道综合文化站',
        position: [121.332978, 28.559708],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 23,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '螺洋街道综合文化站',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '桐屿街道综合文化站',
        position: [121.330272, 28.590477],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 6,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '桐屿街道综合文化站',
            direction: 'left',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '峰江街道综合文化站',
        position: [121.378465, 28.538952],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '峰江街道综合文化站',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '新桥镇综合文化站',
        position: [121.418042, 28.51987],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '新桥镇综合文化站',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '横街镇综合文化站',
        position: [121.443518, 28.53492],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '横街镇综合文化站',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '蓬街镇综合文化站',
        position: [121.488633, 28.55364],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '蓬街镇综合文化站',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '金清镇综合文化站',
        position: [121.523203, 28.499142],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '金清镇综合文化站',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥图书馆',
        position: [121.348228, 28.591423],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥图书馆',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥前蔡和合书吧',
        position: [121.360528, 28.591417],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥前蔡和合书吧',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥钟楼和合书吧',
        position: [121.378569, 28.575488],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥钟楼和合书吧',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥蓬街新阳和合书吧',
        position: [121.474436, 28.54623],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥蓬街新阳和合书吧',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥商城和合书吧',
        position: [121.330387, 28.578665],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥商城和合书吧',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥金大田和合书吧',
        position: [121.401378, 28.512972],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥金大田和合书吧',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥路南和合书吧',
        position: [121.397144, 28.558803],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥路南和合书吧',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥横街和合书吧',
        position: [121.44286, 28.532903],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥横街和合书吧',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥峰江和合书吧',
        position: [121.380195, 28.536023],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
             image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',


            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥峰江和合书吧',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥金清和合书吧',
        position: [121.511819, 28.504111],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥金清和合书吧',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },

];